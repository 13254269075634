import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import './src/css/index.css';

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_CLIENT_KEY ?? ''}>
      {element}
    </GoogleReCaptchaProvider>
  )
}